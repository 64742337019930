import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import 'react-circular-progressbar/dist/styles.css';
import Grid from "@mui/material/Grid";
import GradientLineChart from "../components/Charts/LineCharts/GradientLineChart";
import MDBadgeDot from "../../../components/MDBadgeDot";
import {formatCurrency, formatPercentage} from "../../../util/generalUtils";
import {useState} from "react";
import DefaultCell from "../../../components/DataTable/components/DefaultCell";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "../../../components/DataTable";
import Modal from "@mui/material/Modal";
import {AddProductForm} from "./AddProductForm";
import {EditProductForm} from "./EditProductForm";
import ChatToAdvisor from "./ChatToAdvisor";

export const TaxfreeSavings = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);

    function createArray(number) {
        return Array.from({length: number}, (v, i) => i + 1);
    }

    const handleOpenAddModal = () => {
        setModalType('add'); // Set type to 'add'
        setSelectedProduct(null); // No product selected for adding
        setOpenModal(true);
    };

    const handleOpenEditModal = (product) => {
        setModalType('edit'); // Set type to 'edit'
        setSelectedProduct(product); // Set the product to be edited
        setOpenModal(true);
    };

    const handleCloseModal = () => setOpenModal(false);

    const createProductRows = (products) => {
        return products.map((product) => ({
            provider: (
                <div onClick={() => handleOpenEditModal(product)} style={{cursor: 'pointer'}}>
                    <DefaultCell>{product.productProvider}</DefaultCell>
                </div>
            ),
            currentValue: (
                <div onClick={() => handleOpenEditModal(product)} style={{cursor: 'pointer'}}>
                    <DefaultCell>{formatCurrency(product.productBenefit)}</DefaultCell>
                </div>
            ),
            contribution: (
                <div onClick={() => handleOpenEditModal(product)} style={{cursor: 'pointer'}}>
                    <DefaultCell>{formatCurrency(product.productPremium)}</DefaultCell>
                </div>
            ),
        }));
    };


    const gradientLineChartData = {
        labels: createArray(props.taxFreeTaxBenefitYears),
        datasets: [
            {
                label: "With Tax Free Benefits",
                color: "info",
                data: props.taxFreeWithTaxBenefit,
            },
            {
                label: "Without Tax Free Benefits",
                color: "dark",
                data: props.taxFreeWithoutTaxBenefit,
            },
        ],
    };


    return (
        <MDBox p={2}>
            <MDTypography variant="h4" sx={{mt: 2, mb: 1, px: 2,}}>
                Tax-Free Savings
            </MDTypography>
            <MDTypography variant="h5" fontWeight="medium" sx={{mt: 2, mb: 1, px: 2,}}>
                You are using {formatPercentage(props.taxFreeSavingsPercentage)} of your tax-free
                savings benefit
            </MDTypography>
            <MDBox mb={2} pt={2} pb={2} px={2}>
                <MDTypography variant="h6" sx={{mt: 2, mb: 1}}>
                    All South African taxpayers have access to an incredible tax-free savings benefit of up to R36,000
                    per year. This fantastic opportunity allows you to save for specific goals or prepare for unexpected
                    situations. With a tax-free savings plan, your investments grow tax-free and earn compounded
                    interest over time, saving you even more money, while still giving you the flexibility to access
                    your savings whenever needed. It’s a smart and easy way to build your financial future and ensure
                    long-term security! Your dedicated adviser can help you combine this with other benefits to maximise
                    your savings.
                </MDTypography>
                <ChatToAdvisor source={'Tax Tax-Free Savings'}/>
            </MDBox>

            {/* Line Graph */}
            <MDBox mt={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <GradientLineChart
                            title="Interest Tax Benefit"
                            height="20rem"
                            description="Tax Free Savings Compound tax benefit lost"
                            chart={gradientLineChartData}
                            description={

                                <MDBox display="flex" flexDirection="column" justifyContent="space-between">
                                    <MDBox>
                                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                                            Tax Free Savings Interest Tax Benefit Not Used:
                                        </MDTypography>
                                        <MDBox display="flex" flexDirection="row" alignItems="center">
                                            <MDTypography color="dark" variant="h6">
                                                Est. Loss In Today's Value:&nbsp;
                                            </MDTypography>
                                            <MDTypography color="primary">
                                                {formatCurrency(props.compoundTFSPresentValue)}
                                            </MDTypography>
                                        </MDBox>
                                        <MDBox display="flex" flexDirection="row" alignItems="center">
                                            <MDTypography color="dark" variant="h6">
                                                Est. Loss in {props.taxFreeTaxBenefitYears} years:&nbsp;
                                            </MDTypography>
                                            <MDTypography color="primary">
                                                {formatCurrency(props.taxFreeCompoundLoss)}
                                            </MDTypography>
                                        </MDBox>
                                    </MDBox>
                                    <MDBox display="flex" ml={-1}>


                                        <MDBadgeDot color="info" size="sm" badgeContent="With Tax Free benefits"/>
                                        <MDBadgeDot color="dark" size="sm" badgeContent="Without Tax Free Benefits"/>
                                    </MDBox>
                                </MDBox>
                            }
                        />
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox mt={3}>
                <Card>
                    <MDBox display="flex" flexDirection="column" alignItems="center" mt={3} pt={3} px={3}>
                        <MDTypography variant="h6" fontWeight="medium" align="left">
                            Existing Tax-Free Saving Products
                        </MDTypography>
                        <IconButton onClick={handleOpenAddModal} color="info" sx={{mt: 1}}>
                            <AddIcon/>
                            <MDTypography variant="button" ml={1}>
                                Add Existing Products
                            </MDTypography>
                        </IconButton>
                        {props.products.length > 0 && (
                            <MDTypography mt={2} variant="h6" color='info'>
                                You can edit or delete a product by clicking on it.
                            </MDTypography>
                        )}
                    </MDBox>
                    <MDBox py={1}>
                        {props.products.length === 0 ? (
                            // Display this if there are no products
                            <MDBox
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '8px',
                                    padding: '16px',
                                    textAlign: 'center',
                                    backgroundColor: '#f9f9f9', // Light background
                                    wordWrap: 'break-word', // Ensure text wraps properly
                                    overflowWrap: 'break-word',
                                }}
                            >
                                <MDTypography variant="h6" sx={{wordWrap: 'break-word'}}>
                                    There are no products added yet. You can add products with the button above.
                                </MDTypography>
                            </MDBox>
                        ) : (
                            // Display the table if there are products
                            <DataTable
                                table={{
                                    columns: [
                                        {Header: "Provider", accessor: "provider"},
                                        {Header: "Total Contributions", accessor: "currentValue"},
                                        {Header: "Monthly Contribution", accessor: "contribution"},
                                    ],
                                    rows: createProductRows(props.products), // Render product rows
                                }}
                                entriesPerPage={false}
                                showTotalEntries={false}
                                isSorted={false}
                                noEndBorder
                            />
                        )}
                    </MDBox>


                    <Modal open={openModal} onClose={handleCloseModal}>
                        <MDBox
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: 400,
                                bgcolor: "background.paper",
                                border: "2px solid #000",
                                boxShadow: 24,
                                p: 4,
                            }}
                        >
                            {modalType === 'add' ? (  // Conditional rendering based on modal type
                                <AddProductForm onClose={handleCloseModal} productType={props.productType}
                                                routeType={props.routeType}/>
                            ) : (
                                <EditProductForm
                                    product={selectedProduct}  // Pass the selected product for editing
                                    onClose={handleCloseModal}
                                    productType={props.productType}
                                    routeType={props.routeType}
                                />
                            )}
                        </MDBox>
                    </Modal>
                </Card>
            </MDBox>
        </MDBox>
    );
};