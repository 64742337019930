import {useLoaderData} from 'react-router-dom';
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardCardProgress from "./components/cards/DashboardCardProgress";
import DashboardCard from "./components/cards/DashboardCard";
import Icon from "@mui/material/Icon";
import MDTypography from "../../components/MDTypography";
import Card from "@mui/material/Card";


const MainApp = () => {
    const {taxOptimised, fnaOptimised} = useLoaderData();
    return (
        <>
            <Card sx={{width: "100%", marginTop: 1}}>
                <MDBox display="flex">
                    <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="4rem"
                        height="4rem"
                        variant="gradient"
                        bgColor="info"
                        color="white"
                        shadow="md"
                        borderRadius="xl"
                        ml={3}
                        mt={-2}
                    >
                        <Icon fontSize="medium" color="inherit">
                            assured_workload
                        </Icon>
                    </MDBox>
                    <MDTypography variant="h3" sx={{mt: 2, mb: 1, ml: 2}}>
                        Welcome to your Dashboard
                    </MDTypography>
                </MDBox>
                <MDBox><MDTypography variant="h6" fontWeight='regular' sx={{mt: 2, mb: 2, mx: 2}}>
                    Take control of your finances with GrowYourBucks! Our cutting-edge app provides personalised savings
                    strategies, investment opportunities, tax-saving insights, and expert guidance. Use the top menu or
                    click below to start prospering.
                </MDTypography></MDBox>

            </Card>

            <MDBox py={3}>
                <MDBox mt={1.5}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <DashboardCardProgress
                                    color="dark"
                                    icon="assured_workload"
                                    title="Taxes"
                                    count={Math.round(taxOptimised * 100)}
                                    url="/app/tax"

                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <DashboardCardProgress
                                    icon="account_balance_wallet"
                                    title="Risk & Retirement"
                                    count={Math.round(fnaOptimised * 100)}
                                    url="/app/financial-analysis"

                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <DashboardCard
                                    color="success"
                                    icon="history_edu"
                                    title="Last Will & Testament"
                                    count="0"
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <DashboardCard
                                    color="primary"
                                    icon="credit_score"
                                    title="Credit"
                                    count="0"

                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>

            </MDBox>
        </>
    );
};
export default MainApp;