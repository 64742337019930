import MDBox from "../../components/MDBox";


export const PrivacyPolicyUser = () => {

    return (
        <MDBox>
            <h1>Privacy Policy</h1>
        </MDBox>
    )
}