// PopupCard.jsx
import React, {useEffect, useState} from "react";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FormField from "components/FormField";
import {Form, useLoaderData, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {getCsrfTokenFromCookies} from "util/getCsrfTokenFromCookies";
import MDInput from "../../../components/MDInput";
import {parsePhoneNumberFromString} from "libphonenumber-js";

function PopupCard() {
    const [localValue, setLocalValue] = useState("");
    const [fieldName, setFieldName] = useState("");
    const [error, setError] = useState("");
    const [receiver, setReceiver] = useState("");
    const [codeRequested, setCodeRequested] = useState(false);
    const [codeEntered, setCodeEntered] = useState(false);
    const [code, setCode] = useState("");
    const [sending, setSending] = useState(false);
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [timer, setTimer] = useState(0); // State to store countdown timer
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const loaderData = useLoaderData();
    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id && loaderData) {
            if (id === "firstName") {
                setFieldName("First Name");
                setLocalValue(loaderData.firstName);
            } else if (id === "lastName") {
                setFieldName("Last Name");
                setLocalValue(loaderData.lastName);
            } else if (id === "email") {
                setFieldName("Email");
                setLocalValue(loaderData.email);
            } else if (id === "phone") {
                setFieldName("Phone");
                setLocalValue(loaderData.phone);
            }
        }
    }, [id, loaderData]);

    const handlePhoneChange = (e) => {
        let value = e.target.value;

        // Check if the number is in the 10-digit format starting with 0
        if (value.length === 10 && value.startsWith('0')) {
            value = `+27${value.substring(1)}`; // Convert to international format
        }

        setPhone(value);
        validatePhoneNumber(value);
    };

    // Validate phone number
    const validatePhoneNumber = (value) => {
        if (value) {
            const phoneNumber = parsePhoneNumberFromString(value, 'ZA');
            if (!phoneNumber || (phoneNumber.nationalNumber.length !== 9 && phoneNumber.number.length !== 12)) {
                setPhoneError("Please enter a valid South African phone number.");
            } else {
                setPhoneError("");
            }
        } else {
            setPhoneError("Please enter a phone number.");
        }
    };

    // Handle email change
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleCodeChange = (e) => {
        const inputCode = e.target.value;
        setCode(inputCode);

        if (inputCode.length === 6) {
            setCodeEntered(true);
        } else {
            setCodeEntered(false);
        }
    };

    // Validate email on blur
    const validateEmail = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for email format
        if (email && !emailPattern.test(email)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
        }
    };

    const handleCodeRequest = async (receiver) => {
        setSending(true);
        setError("");
        setReceiver(receiver);
        const csrfToken = await getCsrfTokenFromCookies();
        let payload = {};
        if (id === "email") {
            payload = {
                currentemail: loaderData.email,
                newemail: receiver
            }
        } else if (id === "phone") {
            payload = {
                currentphone: loaderData.phone,
                newphone: receiver
            }
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/auth/requestverificationcode`,
                payload,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        "x-csrf-token": csrfToken,
                    },
                }
            );

            if (response.status === 200) {
                setCodeRequested(true);
                setButtonDisabled(true);
                setTimer(60);
            } else if (response.status === 409) {
                // Handle 409 Conflict by displaying server-provided message
                setError(response.data.message); // Correct: Display the actual error message from the server
            } else {
                // Handle other non-successful responses
                setError("Failed to send verificativvvvon code.");
            }
        } catch (error) {
            // Extract and display the error message correctly
            if (error.response && error.response.data) {
                const errorMessage = typeof error.response.data === 'string'
                    ? error.response.data // If it's a string, use it directly
                    : error.response.data.message; // If it's an object, use the 'message' field
                setError(errorMessage);
            } else {
                setError("Failed to send verification code.");
            }
            console.error("Error sending verification code:", error);
        }

        setSending(false);
    }

    useEffect(() => {
        if (timer > 0) {
            const countdown = setTimeout(() => setTimer(timer - 1), 1000); // Decrement the timer every second
            return () => clearTimeout(countdown); // Clear the timeout when the effect is cleaned up
        } else if (timer === 0) {
            setButtonDisabled(false); // Re-enable the button when the timer reaches zero
        }
    }, [timer]);

    const handleInputChange = (e) => {
        setLocalValue(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const csrfToken = await getCsrfTokenFromCookies();
        const formData = new FormData(e.target);
        let updateFields = {};
        if (id === "phone" || id === "email") {
            updateFields = {
                code: formData.get('code'),
                [id]: receiver,
            };
        } else {
            updateFields = {
                [id]: formData.get("value"),
            };
        }


        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/auth/changeuserdata`,
                updateFields,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        "x-csrf-token": csrfToken,
                    },
                }
            );

            if (response.status === 200) {
                setLoading(false);
                navigate('/app/profile-settings', {state: {reload: true}});
            } else {
                throw new Error("Failed to update basic info.");
            }
        } catch (error) {
            setLoading(false);
            console.error("Error updating basic info:", error);
        }
    };

    return (
        <Modal
            open={true}
            onClose={() => navigate(-1)}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backdropFilter: "blur(5px)",
            }}
        >
            <Card sx={{p: 3, maxWidth: 400, width: "100%"}}>
                <MDBox>
                    <MDTypography id="modal-title" variant="h6" mb={2}>
                        Edit {fieldName}
                    </MDTypography>
                    {id === "email" || id === "phone" ? (
                        <Form method="post" onSubmit={handleSubmit}>
                            {codeRequested ? (
                                <>
                                    <MDTypography variant='h6'>Code sent to: {receiver}</MDTypography>
                                    <MDBox mb={2} mt={2}>
                                        <MDInput
                                            name='code'
                                            type="text"
                                            label="Enter the code that was sent to you"
                                            onChange={handleCodeChange}
                                            fullWidth
                                            value={code}
                                        />
                                    </MDBox>
                                </>
                            ) : (
                                <>
                                    <MDTypography variant='h6'>Current {fieldName}: {localValue}</MDTypography>
                                    <MDBox mb={2} mt={2}>
                                        <MDInput
                                            name={id === "email" ? "email" : "phone"}
                                            type="text"
                                            label={`Enter new ${fieldName}`}
                                            fullWidth
                                            value={id === "email" ? email : phone}
                                            onChange={id === "email" ? handleEmailChange : handlePhoneChange}
                                            onBlur={id === "email" ? () => validateEmail(email) : () => validatePhoneNumber(phone)}
                                        />
                                        {emailError && (
                                            <MDTypography variant="body2" color="error">
                                                {emailError}
                                            </MDTypography>
                                        )}
                                        {phoneError && (
                                            <MDTypography variant="body2" color="error">
                                                {phoneError}
                                            </MDTypography>
                                        )}
                                    </MDBox>
                                </>
                            )}
                            <MDBox>
                                <MDButton
                                    variant="text"
                                    color="info"
                                    onClick={() => handleCodeRequest(id === "email" ? email : phone)}
                                    disabled={
                                        buttonDisabled ||
                                        (id === 'email'
                                            ? (!email || !!emailError || sending)
                                            : (!phone || !!phoneError || sending))
                                    }
                                >
                                    {buttonDisabled ? `Code sent and will arrive shortly. You can resend in ${timer} seconds` : sending ? "Sending" : "Request Code"}

                                </MDButton>
                                {error && (
                                    <MDTypography variant="body2" color="error">
                                        {error}
                                    </MDTypography>
                                )}
                            </MDBox>
                            <MDBox display="flex" justifyContent="flex-end" mt={2}>
                                <MDButton
                                    variant="text"
                                    color="secondary"
                                    onClick={() => navigate(-1)}
                                    disabled={loading}
                                >
                                    Cancel
                                </MDButton>
                                <MDButton type="submit" variant="contained" color="primary" sx={{ml: 2}}
                                          disabled={loading || !codeRequested || !codeEntered}>
                                    {loading ? "Loading..." : "Update"}
                                </MDButton>
                            </MDBox>
                        </Form>
                    ) : (
                        <Form method="post" onSubmit={handleSubmit}>
                            <FormField
                                label={fieldName}
                                name="value"
                                value={localValue}
                                onChange={handleInputChange}
                                inputProps={{
                                    type: fieldName === "email" ? "email" : fieldName === "phone" ? "tel" : "text",
                                }}
                            />
                            <input type="hidden" name="fieldName" value={fieldName}/>
                            <MDBox display="flex" justifyContent="flex-end" mt={2}>
                                <MDButton
                                    variant="text"
                                    color="secondary"
                                    onClick={() => navigate(-1)}
                                    disabled={loading}
                                >
                                    Cancel
                                </MDButton>
                                <MDButton type="submit" variant="contained" color="primary" sx={{ml: 2}}
                                          disabled={loading}>
                                    {loading ? "Loading..." : "Update"}
                                </MDButton>
                            </MDBox>
                        </Form>
                    )}

                </MDBox>
            </Card>
        </Modal>
    );
}

// Keep the loader function
export async function popupCardLoader() {
    const csrfToken = await getCsrfTokenFromCookies();
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/getuserdata`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });

    if (response.status === 200 && response.data) {
        return {
            firstName: response.data.firstname,
            lastName: response.data.lastname,
            email: response.data.email,
            phone: response.data.phone,
        };
    } else {
        throw new Error("Failed to fetch user data");
    }
}

// Keep the action function
export async function popupCardAction({request}) {
    const formData = await request.formData();
    const csrfToken = await getCsrfTokenFromCookies();
    const updateFields = {
        [formData.get("fieldName")]: formData.get("value"),
    };

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/auth/changeuserdata`,
            updateFields,
            {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    "x-csrf-token": csrfToken,
                },
            }
        );

        if (response.status === 200) {
            return {success: true, message: "Basic info updated successfully."};
        } else {
            throw new Error("Failed to update basic info.");
        }
    } catch (error) {
        return {success: false, error: error.message};
    }
}

export default PopupCard;