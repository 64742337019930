import {useEffect, useState} from "react";

// react-router components
import {Link, useLocation} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";

// Material Dashboard 2 PRO React examples
import Breadcrumbs from "pages/main/components/Breadcrumbs";


// Custom styles for DashboardNavbar
import {navbar, navbarContainer, navbarDesktopMenu, navbarIconButton, navbarMobileMenu, navbarRow,} from "./styles";

// Material Dashboard 2 PRO React context
import {setMiniSidenav, setOpenConfigurator, setTransparentNavbar, useMaterialUIController,} from "context";

function DashboardNavbar({absolute = false, light = false, isMini = false, brand}) {
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useMaterialUIController();
    const {miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode} = controller;
    const [openMenu, setOpenMenu] = useState(false);
    const route = useLocation().pathname.split("/").slice(1);

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);

    // Render the notifications menu


    // Styles for the navbar icons
    const iconsStyle = ({palette: {dark, white, text}, functions: {rgba}}) => ({
        color: () => {
            let colorValue = light || darkMode ? white.main : dark.main;

            if (transparentNavbar && !light) {
                colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
            }

            return colorValue;
        },
    });

    return (
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme) => navbar(theme, {transparentNavbar, absolute, light, darkMode})}
        >
            {miniSidenav && (
                <MDBox display="flex" alignItems="center" mb={1} ml={1}>
                    <MDBox component={Link} to="/app" display="flex" alignItems="center">
                        <MDBox component="img" src={brand} alt="Brand" width="2rem"/>
                    </MDBox>
                </MDBox>
            )}
            <Toolbar sx={(theme) => navbarContainer(theme)}>

                <MDBox color="inherit" mb={{xs: 1, md: 0}} sx={(theme) => navbarRow(theme, {isMini})}>
                    <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light}/>
                    <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
                        <Icon fontSize="medium" sx={iconsStyle}>
                            {miniSidenav ? "menu_open" : "menu"}
                        </Icon>
                    </IconButton>
                </MDBox>
                {isMini ? null : (
                    <MDBox sx={(theme) => navbarRow(theme, {isMini})}>
                        <MDBox color={light ? "white" : "inherit"}>
                            <Link to="/app/profile-settings">
                                <IconButton sx={navbarIconButton} size="small" disableRipple>
                                    <Icon sx={iconsStyle}>account_circle</Icon>
                                </IconButton>
                            </Link>
                            <IconButton
                                size="small"
                                disableRipple
                                color="inherit"
                                sx={navbarMobileMenu}
                                onClick={handleMiniSidenav}
                            >
                                <Icon sx={iconsStyle} fontSize="medium">
                                    {miniSidenav ? "menu_open" : "menu"}
                                </Icon>
                            </IconButton>
                            <IconButton
                                size="small"
                                disableRipple
                                color="inherit"
                                sx={navbarIconButton}
                                onClick={handleConfiguratorOpen}
                            >
                                <Icon sx={iconsStyle}>settings</Icon>
                            </IconButton>
                            <IconButton
                                size="small"
                                disableRipple
                                color="inherit"
                                sx={navbarIconButton}
                                aria-controls="notification-menu"
                                aria-haspopup="true"
                                variant="contained"
                                onClick={handleOpenMenu}
                            >
                                <MDBadge badgeContent={0} color="error" size="xs" circular>
                                    <Icon sx={iconsStyle}>notifications</Icon>
                                </MDBadge>
                            </IconButton>
                        </MDBox>
                    </MDBox>
                )}
            </Toolbar>
        </AppBar>
    );
}


// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
    brand: PropTypes.string
};

export default DashboardNavbar;
