import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import 'react-circular-progressbar/dist/styles.css';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import Icon from "@mui/material/Icon";
import {LifeProvision} from "./components/LifeProvision";
import {DisabilityProvision} from "./components/DisabilityProvision";
import {RetirementProvision} from "./components/RetirementProvision";
import {getCsrfTokenFromCookies} from "../../util/getCsrfTokenFromCookies";
import axios from "axios";
import {useLoaderData} from "react-router-dom";
import {formatCurrency, formatPercentage} from "../../util/generalUtils";
import {DreadedDiseaseProvision} from "./components/DreadedDiseaseProvision";
import ChatToAdvisor from "./components/ChatToAdvisor";

export const FinancialAnalysis = () => {
    const loaderData = useLoaderData();
    const retirementProducts = loaderData.products.filter(product => product.productType === 'Retirement');
    const lifeProducts = loaderData.products.filter(product => product.productType === 'Life');
    const disabilityProducts = loaderData.products.filter(product => product.productType === 'Disability');
    const criticalillnessProducts = loaderData.products.filter(product => product.productType === 'CriticalIllness');

    return (
        <>
            <Card sx={{width: "100%", marginTop: 1}}>
                <MDBox display="flex">
                    <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="4rem"
                        height="4rem"
                        variant="gradient"
                        bgColor="info"
                        color="white"
                        shadow="md"
                        borderRadius="xl"
                        ml={3}
                        mt={-2}
                    >
                        <Icon fontSize="medium" color="inherit">
                            assured_workload
                        </Icon>
                    </MDBox>
                    <MDTypography variant="h3" sx={{mt: 2, mb: 1, ml: 2}}>
                        Your Risk and Retirement Financial Need Analysis
                    </MDTypography>
                </MDBox>
                <MDBox><MDTypography variant="h6" sx={{mt: 2, mb: 1, ml: 2}}>
                    Below is a comprehensive financial needs analysis, covering your risk exposure in the event of
                    death, disability, or serious illness, as well as provisions for your retirement. These areas
                    form a crucial cornerstone of wealth planning, ensuring you are protected against unforeseen
                    events and can enjoy a comfortable life when you retire, with complete peace of mind.
                </MDTypography></MDBox>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <MDBox display="flex" alignItems="center" mt={3} mb={3} mx={3}>
                            <MDBox mr={2} bgColor='info' variant='gradient' pt={2} px={2} pb={2} shadow="md"
                                   borderRadius="xl">
                                <CircularProgressbar value={loaderData.fna.totalFinancialNeedsPercentage}
                                                     text={formatPercentage(loaderData.fna.totalFinancialNeedsPercentage)}
                                                     styles={buildStyles({
                                                         trailColor: 'white',
                                                         textColor: 'white',
                                                         pathColor: '#344767',// Example of adding more styles
                                                     })}
                                />
                            </MDBox>
                            <MDBox lineHeight={1}>
                                <MDTypography variant="h6" fontWeight="medium">
                                    Your financial needs
                                    are {formatPercentage(loaderData.fna.totalFinancialNeedsPercentage)} covered
                                </MDTypography>
                                <MDTypography variant="h6" fontWeight="medium" mt={1}>
                                    Your Total Est. Financial Need: <MDTypography
                                    color='dark'>{formatCurrency(loaderData.fna.totalFinancialNeeds)}</MDTypography>
                                </MDTypography>
                                <MDTypography variant="h6" fontWeight="medium" mt={1}>
                                    Your Total Est. Financial Provision: <MDTypography
                                    color='info'>{formatCurrency(loaderData.fna.totalFinancialNeedsProvision)}</MDTypography>
                                </MDTypography>
                                <MDTypography variant="h6" fontWeight="medium" mt={1}>
                                    Your Total Est. Shortfall: <MDTypography
                                    color='primary'>{formatCurrency(loaderData.fna.totalFinancialNeedsShortfall)}</MDTypography>
                                </MDTypography>


                            </MDBox>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{textAlign: "right"}} px={3}>
                        <ChatToAdvisor source={"FNA"}/>
                        <MDBox mt={0.5}>
                            <MDTypography variant="button" color="text">
                                Get in touch with your designated advisor to get piece of mind.
                            </MDTypography>
                        </MDBox>
                    </Grid>
                </Grid>
            </Card>
            <Card sx={{width: "100%", marginTop: 2, marginBottom: 2}}>
                <LifeProvision
                    products={lifeProducts}
                    deathIncomeNeeded={loaderData.fna.deathIncomeNeeded}
                    deathOtherNeeded={loaderData.fna.deathOtherNeeded}
                    deathTotalNeeded={loaderData.fna.deathTotalNeeded}
                    deathProvision={loaderData.fna.deathProvision}
                    deathShortfall={loaderData.fna.deathShortfall}
                    deathYears={loaderData.fna.deathYears}
                    deathIncomePercentage={loaderData.fna.deathIncomePercentage}
                    deathPercentageOptimised={loaderData.fna.deathPercentageOptimised}
                    productType="Life"
                    routeType={loaderData.routeType}
                />
            </Card>
            <Card sx={{width: "100%", marginTop: 2, marginBottom: 2}}>
                <DisabilityProvision
                    products={disabilityProducts}
                    disabilityIncomeNeeded={loaderData.fna.disabilityIncomeNeeded}
                    disabilityOtherNeeded={loaderData.fna.disabilityOtherNeeded}
                    disabilityTotalNeeded={loaderData.fna.disabilityTotalNeeded}
                    disabilityProvision={loaderData.fna.disabilityProvision}
                    disabilityShortfall={loaderData.fna.disabilityShortfall}
                    disabilityYears={loaderData.fna.disabilityYears}
                    disabilityIncomePercentage={loaderData.fna.disabilityIncomePercentage}
                    disabilityPercentageOptimised={loaderData.fna.disabilityPercentageOptimised}
                    productType="Disability"
                    routeType={loaderData.routeType}
                />
            </Card>
            <Card sx={{width: "100%", marginTop: 2, marginBottom: 2}}>
                <DreadedDiseaseProvision
                    products={criticalillnessProducts}
                    criticalIllnessCoverNeeded={loaderData.fna.criticalIllnessCoverNeeded}
                    criticalIllnessProvision={loaderData.fna.criticalIllnessProvision}
                    criticalIllnessShortfall={loaderData.fna.criticalIllnessShortfall}
                    criticalIllnessMultiple={loaderData.fna.criticalIllnessMultiple}
                    criticalIllnessPercentageOptimised={loaderData.fna.criticalIllnessPercentageOptimised}
                    productType="CriticalIllness"
                    routeType={loaderData.routeType}
                />
            </Card>
            <Card sx={{width: "100%", marginTop: 2, marginBottom: 2}}>
                <RetirementProvision
                    products={retirementProducts}
                    retirementProvisionNeeded={loaderData.fna.retirementProvisionNeeded}
                    retirementProvision={loaderData.fna.retirementProvision}
                    retirementProvisionShortfall={loaderData.fna.retirementProvisionShortfall}
                    retirementProvisionNeededOverTime={loaderData.fna.retirementProvisionNeededOverTime}
                    retirementProvisionOverTime={loaderData.fna.retirementProvisionOverTime}
                    yearsInRetirement={loaderData.fna.yearsInRetirement}
                    yearsToRetirement={loaderData.fna.yearsToRetirement}
                    retirementAge={loaderData.fna.retirementAge}
                    extraMonthlyContributionNeeded={loaderData.fna.extraMonthlyContributionNeeded}
                    retirementProvisionPercentage={loaderData.fna.retirementProvisionPercentage}
                    retirementPresentValue={loaderData.fna.retirementPresentValue}
                    retirementProvisionPercentageOptimised={loaderData.fna.retirementProvisionPercentageOptimised}
                    productType="Retirement"
                    routeType={loaderData.routeType}
                />
            </Card>
        </>
    );
}

export const financialAnalysisLoader = async () => {
    const routeType = 'client';
    const csrfToken = await getCsrfTokenFromCookies()
    const userDataResponse = await axios.get(`${process.env.REACT_APP_API_URL}/auth/getuserdata`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });
    if (userDataResponse.data.basicProfileSetup === false) {
        window.location.href = '/app';
        return null;
    }
    const fnaResponse = await axios.get(`${process.env.REACT_APP_API_URL}/clients/getFnaData`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });
    const productsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/clients/getUserProducts`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });
    if (fnaResponse.status === 200 && productsResponse.data) {
        return {
            fna: fnaResponse.data,
            products: productsResponse.data,
            routeType: routeType,
        };
    } else {
        throw new Error("Failed to fetch user data");
    }
}

export const adminFinancialAnalysisLoader = async ({params}) => {
    const routeType = 'admin';
    const csrfToken = await getCsrfTokenFromCookies()
    const userDataResponse = await axios.get(`${process.env.REACT_APP_API_URL}/provider/getuserdata/${params.clientId}`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });
    if (userDataResponse.data.basicProfileSetup === false) {
        window.location.href = '/app';
        return null;
    }
    const fnaResponse = await axios.get(`${process.env.REACT_APP_API_URL}/provider/getFnaData/${params.clientId}`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });
    const productsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/provider/getUserProducts/${params.clientId}`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });
    if (fnaResponse.status === 200 && productsResponse.data) {
        return {
            fna: fnaResponse.data,
            products: productsResponse.data,
            routeType: routeType,
        };
    } else {
        throw new Error("Failed to fetch user data");
    }
}