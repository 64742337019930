import React, {useEffect, useState} from 'react';
import {Form, Link, redirect, useActionData} from "react-router-dom";
import axios from "axios";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "pages/public/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import {parsePhoneNumberFromString} from 'libphonenumber-js';

function Signup() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const actionData = useActionData();
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        console.log('Action data received:', actionData); // Debugging output

        // Check if actionData contains an error message
        if (actionData && typeof actionData === 'object' && actionData.error) {
            console.log('Resetting submit state due to error'); // Debugging output
            setIsSubmitting(false); // Reset submitting state on error
        }
    }, [actionData]);
    // Handle password change
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    // Handle confirm password change
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    // Validate passwords on blur
    const validatePasswords = () => {
        if (password && confirmPassword && password !== confirmPassword) {
            setPasswordError("Passwords do not match");
        } else {
            setPasswordError("");
        }
    };

    // Handle phone number change
    const handlePhoneChange = (e) => {
        let value = e.target.value;

        // Check if the number is in the 10-digit format starting with 0
        if (value.length === 10 && value.startsWith('0')) {
            value = `+27${value.substring(1)}`; // Convert to international format
        }

        setPhone(value);
        validatePhoneNumber(value);
    };

    // Validate phone number
    const validatePhoneNumber = (value) => {
        if (value) {
            const phoneNumber = parsePhoneNumberFromString(value, 'ZA');
            if (!phoneNumber || (phoneNumber.nationalNumber.length !== 9 && phoneNumber.number.length !== 12)) {
                setPhoneError("Please enter a valid South African phone number.");
            } else {
                setPhoneError("");
            }
        } else {
            setPhoneError("Please enter a phone number.");
        }
    };

    // Handle email change
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    // Validate email on blur
    const validateEmail = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for email format
        if (email && !emailPattern.test(email)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
        }
    };

    // Handle form submission
    const handleSubmit = (e) => {
        if (passwordError || phoneError || emailError) {
            e.preventDefault(); // Prevent form submission if there are validation errors
        } else {
            setIsSubmitting(true);
        }
    };

    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Sign Up
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <Form method="post" onSubmit={handleSubmit}>
                        <MDBox mb={2}>
                            <MDInput name="firstname" type="text" label="First Name" fullWidth required/>
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput name="lastname" type="text" label="Last Name" fullWidth required/>
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput
                                name="phone"
                                type="text"
                                label="Phone Number"
                                fullWidth
                                value={phone}
                                onChange={handlePhoneChange}
                                onBlur={() => validatePhoneNumber(phone)} // Validate on blur
                            />
                            {phoneError && (
                                <MDTypography variant="body2" color="error">
                                    {phoneError}
                                </MDTypography>
                            )}
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput
                                name="email"
                                type="email"
                                label="Email"
                                fullWidth
                                required
                                value={email}
                                onChange={handleEmailChange}
                                onBlur={validateEmail} // Validate on blur
                            />
                            {emailError && (
                                <MDTypography variant="body2" color="error">
                                    {emailError}
                                </MDTypography>
                            )}
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput
                                name="password"
                                type="password"
                                label="Password"
                                fullWidth
                                required
                                value={password}
                                onChange={handlePasswordChange}
                                onBlur={validatePasswords} // Validate on blur
                            />
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput
                                name="password2"
                                type="password"
                                label="Re-Type Password"
                                fullWidth
                                required
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                onBlur={validatePasswords} // Validate on blur
                            />
                            {passwordError && (
                                <MDTypography variant="body2" color="error">
                                    {passwordError}
                                </MDTypography>
                            )}
                        </MDBox>
                        {actionData && typeof actionData === 'object' && actionData.error && (
                            <MDBox mb={2}>
                                <MDTypography variant="body2" color="error" align="center">
                                    {actionData.error} {/* Correctly access the error property */}
                                </MDTypography>
                            </MDBox>
                        )}
                        <MDBox mt={4} mb={1}>
                            <MDButton
                                type="submit"
                                variant="gradient"
                                color="info"
                                fullWidth
                                disabled={!!passwordError || !!phoneError || !!emailError} // Disable button if there are any validation errors
                            >
                                {isSubmitting ? 'Signing up...' : 'Signup'}
                            </MDButton>
                        </MDBox>
                        <MDBox mt={3} mb={1} textAlign="center">
                            <MDTypography variant="button" color="text">
                                Already Have an account?{" "}
                                <MDTypography
                                    component={Link}
                                    to="/login"
                                    variant="button"
                                    color="info"
                                    fontWeight="medium"
                                    textGradient
                                >
                                    Login
                                </MDTypography>
                            </MDTypography>
                        </MDBox>
                    </Form>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}

export default Signup;

// Action function for form submission
export async function signupAction({request}) {
    const formData = await request.formData();
    const postData = Object.fromEntries(formData);

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/auth/signup`,
            {
                firstname: postData.firstname,
                lastname: postData.lastname,
                phone: postData.phone,
                email: postData.email,
                password: postData.password,
            },
            {withCredentials: true}
        );
        if (response.status === 201) {
            return redirect(response.data.redirectUrl);
        }
    } catch (error) {
        if (error.response && error.response.status === 403) {
            const errorMessage = error.response.data;
            if (errorMessage === "403 Error: User exists already, please login instead.") {
                return {error: "User already exists. Please login instead by clicking login below."};
            } else if (errorMessage === "403 Error: Insecure password, please try again.") {
                return {error: "Your password is not secure. It must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special symbol. Please try again with a stronger password."};
            }
        }

        // Return a generic error message for other cases
        return {error: "An error occurred. Please try again later."};
    }
}