import Login, {loginAction} from 'pages/public/Login.js';
import Signup, {signupAction} from "../pages/public/Signup";
import Verifyphone, {verifyPhoneAction} from "../pages/public/Verifyphone";
import Editphone, {editPhoneAction} from "../pages/public/Editphone";
import {RequestPasswordReset, requestPasswordResetAction} from "../pages/public/RequestPasswordReset";
import {ResetRequested, resetRequestedLoader} from "../pages/public/ResetRequested";
import {PasswordReset, passwordResetAction, passwordResetLoader} from "../pages/public/PasswordReset";
import {VerifyEmail, verifyEmailLoader} from "../pages/public/VerifyEmail";

const publicRoutes = [
    {
        path: '/',
    },
    {
        path: '/login',
        element: <Login/>,
        action: loginAction,
    },
    {
        path: '/verifyphone',
        element: <Verifyphone/>,
        action: verifyPhoneAction
    },
    {
        path: '/editphone',
        element: <Editphone/>,
        action: editPhoneAction
    },
    {
        path: '/signup',
        element: <Signup/>,
        action: signupAction,
    },
    {
        path: '/requestpasswordreset',
        element: <RequestPasswordReset/>,
        action: requestPasswordResetAction,
    },
    {
        path: '/resetrequested',
        element: <ResetRequested/>,
        loader: resetRequestedLoader
    },
    {
        path: '/passwordreset',
        element: <PasswordReset/>,
        loader: passwordResetLoader,
        action: passwordResetAction
    },
    {
        path: '/verifyemail',
        element: <VerifyEmail/>,
        loader: verifyEmailLoader
    }
];

export default publicRoutes;