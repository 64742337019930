import Cookies from "js-cookie";

export function getCsrfTokenFromCookies() {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const csrfToken = Cookies.get('csrfToken');
            if (csrfToken) {
                resolve(csrfToken);
            } else {
                console.error('CSRF token not found');
                reject(new Error('CSRF token not found'));
            }
        }, 100); // Delay for 100ms before attempting to read the cookie
    });
}