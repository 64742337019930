import React from "react";
import {Outlet, useLoaderData, useLocation, useParams} from 'react-router-dom';
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Sidenav from "./components/Sidenav";
import Header from "./components/Header";
import BasicInfo from "./components/BasicInfo";
import LogActivity from "./components/LogActivity";
import {getCsrfTokenFromCookies} from "../../../util/getCsrfTokenFromCookies";
import axios from "axios";

export const ClientDetail = () => {
    const {clientId} = useParams();
    const clientData = useLoaderData();
    const location = useLocation();

    const renderContent = () => {
        const baseClientDetailPath = `/app/admin/client-list/client-details/${clientId}`;

        if (location.pathname !== baseClientDetailPath) {
            return (
                <Grid item xs={12}>
                    <Outlet/>
                </Grid>
            ) // This will render the TaxSummary component
        } else {
            return (
                <>
                    <Grid item xs={12}>
                        <LogActivity clientId={clientId}/>
                    </Grid>
                    <Grid item xs={12}>
                        <BasicInfo clientData={clientData.clientDetail}/>
                    </Grid>
                </>
            );
        }
    };

    return (
        <MDBox mt={4}>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={3}>
                    <Sidenav
                        basicProfileSetup={clientData.clientDetail.basicProfileSetup}
                    />
                </Grid>
                <Grid item xs={12} lg={9}>
                    <MDBox mb={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Header
                                    clientFirstName={clientData.clientDetail.firstName}
                                    clientLastName={clientData.clientDetail.lastName}
                                    basicProfileSetup={clientData.clientDetail.basicProfileSetup}
                                />
                            </Grid>
                            {renderContent()}
                        </Grid>
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    );
}

export const clientDetailLoader = async ({params}) => {
    const csrfToken = await getCsrfTokenFromCookies();
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/provider/getClientDetails/${params.clientId}`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });
    return response.data;
}