import React, {useEffect, useState} from "react";
import BasicLayout from "./components/BasicLayout";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import {Form, useActionData, useLoaderData, useNavigate} from "react-router-dom";
import bgImage from "../../assets/images/bg-sign-in-basic.jpeg";
import axios from "axios";
import {ClipLoader} from "react-spinners";

export const PasswordReset = () => {
    const {token, email, phone} = useLoaderData();
    const actionData = useActionData();
    const navigate = useNavigate(); // Hook to programmatically navigate

    // State for form validation and submission
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [passwordConfirmError, setPasswordConfirmError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false); // Loading state
    const [countdown, setCountdown] = useState(5); // Countdown timer

    // Function to validate password requirements
    const validatePassword = (value) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/; // At least 8 chars, uppercase, lowercase, number, special char
        return regex.test(value);
    };

    // Handle password field change
    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);

        // Check if password meets requirements
        if (!validatePassword(value)) {
            setPasswordError(
                "Password must be at least 8 characters long, contain uppercase, lowercase, a number, and a special character."
            );
        } else {
            setPasswordError(""); // Clear error
        }
    };

    // Handle password confirmation field change
    const handlePasswordConfirmChange = (e) => {
        const value = e.target.value;
        setPasswordConfirm(value);

        // Check if passwords match
        if (password && value !== password) {
            setPasswordConfirmError("Passwords do not match.");
        } else {
            setPasswordConfirmError(""); // Clear error
        }
    };

    // Countdown effect for redirect after successful password reset
    useEffect(() => {
        let timer;
        if (actionData?.success) {
            timer = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [actionData]);

    // Redirect effect when countdown reaches zero
    useEffect(() => {
        if (countdown === 0) {
            navigate("/login"); // Use navigate to redirect
        }
    }, [countdown, navigate]);

    // Show spinner when submitting
    const isSubmitDisabled = Boolean(
        passwordError || passwordConfirmError || !password || !passwordConfirm || isSubmitting
    ); // Ensure this is a boolean

    if (!token) {
        return <div>Invalid request: missing token.</div>;
    }

    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    py={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                        Set Your Password
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Please set your new password
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    {/* If password reset is successful, show success message */}
                    {actionData?.success ? (
                        <MDTypography variant="h6" color="success" align="center">
                            Your password was set successfully! You will be redirected in {countdown} seconds.
                        </MDTypography>
                    ) : (
                        <Form method="post">
                            <input type="hidden" name="token" value={token}/>
                            {email && <input type="hidden" name="email" value={email}/>}
                            {phone && <input type="hidden" name="phone" value={phone}/>}
                            <MDBox mb={4}>
                                <MDInput
                                    name="password"
                                    type="password"
                                    label="Password"
                                    variant="standard"
                                    fullWidth
                                    onBlur={handlePasswordChange}
                                    error={!!passwordError}
                                    helperText={passwordError}
                                />
                            </MDBox>
                            <MDBox mb={4}>
                                <MDInput
                                    name="passwordConfirm"
                                    type="password"
                                    label="Retype Password"
                                    variant="standard"
                                    fullWidth
                                    onBlur={handlePasswordConfirmChange}
                                    error={!!passwordConfirmError}
                                    helperText={passwordConfirmError}
                                />
                            </MDBox>
                            <MDBox mt={6} mb={1}>
                                {isSubmitting ? (
                                    <ClipLoader size={20} color={"#fff"} loading={true}/>
                                ) : (
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        fullWidth
                                        type="submit"
                                        disabled={isSubmitDisabled}
                                    >
                                        Set
                                    </MDButton>
                                )}
                            </MDBox>
                        </Form>
                    )}

                    {/* Display server-side error message, if any */}
                    {actionData && actionData.error && (
                        <MDTypography variant="body2" color="error" mt={2}>
                            {actionData.error}
                        </MDTypography>
                    )}
                </MDBox>
            </Card>
        </BasicLayout>
    );
};

export const passwordResetAction = async ({request}) => {
    const formData = await request.formData();
    const token = formData.get('token');
    const email = formData.get('email') || undefined;
    const phone = formData.get('phone') || undefined;
    const password = formData.get('password');
    const passwordConfirm = formData.get('passwordConfirm');

    // Check if passwords match
    if (password !== passwordConfirm) {
    }

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/auth/passwordreset`,
            {
                email,
                phone,
                token,
                password,
            }
        );
        if (response.status === 200) {
            return {success: true}; // Redirect on successful reset
        }
    } catch (error) {
        console.error('Error resetting password:', error);
        return {error: error.response?.data?.message || 'Failed to reset password. Please try again.'};
    }
};

export const passwordResetLoader = async ({request}) => {
    const searchParams = new URL(request.url).searchParams;
    const token = searchParams.get('token');
    const email = searchParams.get('email') || undefined;
    let phone = searchParams.get('phone') || undefined;
    if (phone) {
        phone = phone.replace(/ /g, '+');  // Replace any space with +
    }

    return {token, email, phone};
};