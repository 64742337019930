import React from 'react';
import {Icon} from '@mui/material';
import MainApp from 'pages/main/MainApp';
import {ProfileSettings, profileSettingsLoader} from "../pages/main/ProfileSettings";
import PopupCard, {popupCardAction, popupCardLoader} from '../pages/main/components/PopupCard';
import {TaxSummary, taxSummaryLoader} from "../pages/main/TaxSummary";
import {FinancialAnalysis, financialAnalysisLoader} from "../pages/main/FinancialAnalysis";
import Logout from '../util/Logout';
import {CreateServiceProfile, createServiceProfileAction} from "../pages/main/admin/createServiceProfile";
import {ClientList, clientsLoader} from "../pages/main/admin/ClientList";
import {Messages, messagesLoader} from "../pages/main/admin/Messages";


const routes = [
    {
        type: 'collapse',
        name: 'Dashboard',
        key: 'dashboard',
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: '/app',
        element: <MainApp/>,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Tax',
        key: 'Tax',
        icon: <Icon fontSize="small">assured_workload</Icon>,
        route: '/app/tax',
        element: <TaxSummary/>,
        noCollapse: true,
        loader: taxSummaryLoader,
    },
    {
        type: 'collapse',
        name: 'Risk and Retirement FNA',
        key: 'Risk and Retirement FNA',
        icon: <Icon fontSize="small">account_balance_wallet</Icon>,
        route: '/app/financial-analysis',
        element: <FinancialAnalysis/>,
        noCollapse: true,
        loader: financialAnalysisLoader,

    },
    {
        type: 'collapse',
        name: 'Settings',
        key: 'settings',
        icon: <Icon fontSize="small">settings</Icon>,
        collapse: [
            {
                name: 'Profile Settings',
                key: 'profile-settings',
                route: '/app/profile-settings',
                element: <ProfileSettings/>,
                loader: profileSettingsLoader,
                id: 'profile-settings',
                children: [
                    {
                        path: 'editbasicinfo/:id',
                        element: <PopupCard/>,
                        action: popupCardAction,
                        loader: popupCardLoader
                    },
                ],
            },
        ],
    },
    {
        type: 'collapse',
        name: 'Logout',
        key: 'logout',
        icon: <Icon fontSize="small">logout</Icon>,
        route: '/app/logout',
        element: <Logout/>,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'ServiceProvider',
        key: 'service-provider',
        icon: <Icon fontSize="small">supervisor_account_icon</Icon>,
        showIf: (profileType) => ['SuperAdmin', 'Advisor'].includes(profileType),
        collapse: [
            {
                name: 'Client List',
                key: 'client-list',
                route: '/app/admin/client-list',
                element: <ClientList/>,
                loader: clientsLoader,
                showIf: (profileType) => ['SuperAdmin', 'Advisor'].includes(profileType),
            },
            {
                name: 'Client Messages',
                key: 'client-messages',
                route: "app/admin/messages",
                element: <Messages/>,
                loader: messagesLoader,
            },
        ]

    },
    {
        type: 'collapse',
        name: 'Super Admin',
        key: 'super-admin',
        icon: <Icon fontSize="small">admin_panel_settings</Icon>,
        showIf: (profileType) => profileType === 'SuperAdmin',
        collapse: [
            {
                name: 'Create Service Profile',
                key: 'create-service-profile',
                route: '/app/admin/create-service-profile',
                element: <CreateServiceProfile/>,
                action: createServiceProfileAction,
                showIf: (profileType) => profileType === 'SuperAdmin',
            }
        ]

    }
];

export default routes;