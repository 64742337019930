import {useState} from "react";
import Card from "@mui/material/Card";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import 'react-circular-progressbar/dist/styles.css';
import VerticalBarChart from "../../main/components/Charts/BarCharts/VerticalBarChart";
import Grid from "@mui/material/Grid";
import DefaultLineChart from "../components/Charts/LineCharts/DefaultLineChart";
import MDBadgeDot from "../../../components/MDBadgeDot";
import {formatCurrency, formatPercentage} from "../../../util/generalUtils";
import DataTable from "../../../components/DataTable";
import DefaultCell from "../../../components/DataTable/components/DefaultCell";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import {AddProductForm} from "./AddProductForm";
import {EditProductForm} from "./EditProductForm";
import ChatToAdvisor from "./ChatToAdvisor";

export const RetirementContributions = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleOpenAddModal = () => {
        setModalType('add'); // Set type to 'add'
        setSelectedProduct(null); // No product selected for adding
        setOpenModal(true);
    };

    const handleOpenEditModal = (product) => {
        setModalType('edit'); // Set type to 'edit'
        setSelectedProduct(product); // Set the product to be edited
        setOpenModal(true);
    };

    const handleCloseModal = () => setOpenModal(false);

    const createProductRows = (products) => {
        return products.map((product) => ({
            provider: (
                <div onClick={() => handleOpenEditModal(product)} style={{cursor: 'pointer'}}>
                    <DefaultCell>{product.productProvider}</DefaultCell>
                </div>
            ),
            currentValue: (
                <div onClick={() => handleOpenEditModal(product)} style={{cursor: 'pointer'}}>
                    <DefaultCell>{formatCurrency(product.productBenefit)}</DefaultCell>
                </div>
            ),
            contribution: (
                <div onClick={() => handleOpenEditModal(product)} style={{cursor: 'pointer'}}>
                    <DefaultCell>{formatCurrency(product.productPremium)}</DefaultCell>
                </div>
            ),
        }));
    };


    function createArray(number) {
        return Array.from({length: number}, (v, i) => i + 1);
    }


    const verticalBarChartData = {
        labels: ["Benefit Available", "Benefit Used", "Benefit Remaining"],
        datasets: [
            {
                label: "Benefits",
                color: ["dark", "info", "primary"],  // An array of colors
                data: [props.retirementBenefitAvailable, props.retirementBenefitUsed, props.retirementBenefitRemaining],
            },
        ],
    };

    const defaultLineChartData = {
        labels: createArray(props.retirementTaxBenefitYears),
        datasets: [
            {
                label: "With Tax Free Benefits",
                color: "info",
                data: props.retirementWithTaxBenefit,
            },
            {
                label: "Without Tax Free Benefits",
                color: "dark",
                data: props.retirementWithoutTaxBenefit,
            },
        ],
    };

    return (
        <MDBox p={2}>
            <MDTypography variant="h4" sx={{mt: 2, mb: 1, pt: 2, pb: 2, px: 2}}>
                Retirement Contributions
            </MDTypography>
            <MDTypography variant="h5" fontWeight="medium" sx={{mt: 1, mb: 1, pt: 2, pb: 2, px: 2}}>
                You are using {formatPercentage(props.retirementBenefitOptimisationPercentage)} of
                your
                retirement tax benefits.
            </MDTypography>

            {/* Grid for Bar Chart and Tax Portfolio */}
            <MDBox pt={2} pb={2} px={2}>
                <MDBox mb={2}>
                    <MDTypography variant="h6" sx={{mt: 1, mb: 1}}>
                        Contributing to a retirement fund offers significant tax benefits, helping you save for the
                        future while reducing your monthly tax obligations to SARS. On top of that, your investments
                        grow tax-free, providing you with a double tax benefit. By investing in this vehicle, you not
                        only build financial security but also ensure a more comfortable future. Your dedicated
                        financial adviser can help you structure this to maximise the benefits.
                    </MDTypography>
                    <ChatToAdvisor source={'Tax Retirement'}/>
                </MDBox>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Card sx={{p: 2, height: "20rem"}}>
                            <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center"
                                   height="100%">
                                <MDTypography variant="h5" fontWeight="medium" mt={2}>
                                    Tax Reduction Benefit:
                                </MDTypography>
                                <MDTypography variant="h6" fontWeight="medium" mt={1}>
                                    Est. Tax Reduction Benefit Available:
                                </MDTypography>
                                <MDTypography
                                    color="dark">{formatCurrency(props.retirementBenefitAvailable)}</MDTypography>
                                <MDTypography variant="h6" fontWeight="medium" mt={2}>
                                    Est. Tax Reduction Benefit Used:
                                </MDTypography>
                                <MDTypography color="info">{formatCurrency(props.retirementBenefitUsed)}</MDTypography>
                                <MDTypography variant="h6" fontWeight="medium" mt={2}>
                                    Est. Tax Reduction Benefit Unused:
                                </MDTypography>
                                <MDTypography
                                    color="primary">{formatCurrency(props.retirementBenefitRemaining)}</MDTypography>

                            </MDBox>
                        </Card>
                    </Grid>

                    {/* Bar Chart Card (Right) */}
                    <Grid item xs={12} md={6}>
                        <Card sx={{p: 2, height: "20rem"}}>
                            <VerticalBarChart
                                height="100%"  // Ensuring the chart takes full height inside the card
                                chart={verticalBarChartData}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

            {/* Line Graph */}
            <MDBox mt={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DefaultLineChart
                            title="Interest Tax Benefit"
                            description={

                                <MDBox display="flex" flexDirection="column" justifyContent="space-between">
                                    <MDBox>
                                        <MDTypography variant="h6" fontWeight="medium" mt={2}>
                                            Retirement Interest Tax Benefit Not Used:
                                        </MDTypography>
                                        <MDBox display="flex" flexDirection="row" alignItems="center">
                                            <MDTypography color="dark" variant="h6">
                                                Est. Loss In Today's Value:&nbsp;
                                            </MDTypography>
                                            <MDTypography color="primary">
                                                {formatCurrency(props.compoundPresentValue)}
                                            </MDTypography>
                                        </MDBox>
                                        <MDBox display="flex" flexDirection="row" alignItems="center">
                                            <MDTypography color="dark" variant="h6">
                                                Est. Loss At Retirement:&nbsp;
                                            </MDTypography>
                                            <MDTypography color="primary">
                                                {formatCurrency(props.retirementTaxCompoundedLoss)}
                                            </MDTypography>
                                        </MDBox>
                                    </MDBox>
                                    <MDBox display="flex" ml={-1}>
                                        <MDBadgeDot color="info" size="sm" badgeContent="With Benefit"/>
                                        <MDBadgeDot color="dark" size="sm" badgeContent="Without Benefit"/>
                                    </MDBox>
                                </MDBox>
                            }
                            chart={defaultLineChartData}
                        />
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox mt={3}>
                <Card>
                    <MDBox display="flex" flexDirection="column" alignItems="center" mt={3} pt={3} px={3}>
                        <MDTypography variant="h6" fontWeight="medium" align="left">
                            Existing Retirement Products
                        </MDTypography>
                        <IconButton onClick={handleOpenAddModal} color="info" sx={{mt: 1}}>
                            <AddIcon/>
                            <MDTypography variant="button" ml={1}>
                                Add Existing Products
                            </MDTypography>
                        </IconButton>
                        {props.products.length > 0 && (
                            <MDTypography mt={2} variant="h6" color='info'>
                                You can edit or delete a product by clicking on it.
                            </MDTypography>
                        )}
                    </MDBox>
                    <MDBox py={1}>
                        {props.products.length === 0 ? (
                            // Display this if there are no products
                            <MDBox
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '8px',
                                    padding: '16px',
                                    textAlign: 'center',
                                    backgroundColor: '#f9f9f9', // Light background
                                    wordWrap: 'break-word', // Ensure text wraps properly
                                    overflowWrap: 'break-word',
                                }}
                            >
                                <MDTypography variant="h6" sx={{wordWrap: 'break-word'}}>
                                    There are no products added yet. You can add products with the button above.
                                </MDTypography>
                            </MDBox>
                        ) : (
                            // Display the table if there are products
                            <DataTable
                                table={{
                                    columns: [
                                        {Header: "Provider", accessor: "provider"},
                                        {Header: "Current Value", accessor: "currentValue"},
                                        {Header: "Contribution", accessor: "contribution"},
                                    ],
                                    rows: createProductRows(props.products), // Render product rows
                                }}
                                entriesPerPage={false}
                                showTotalEntries={false}
                                isSorted={false}
                                noEndBorder
                            />
                        )}
                    </MDBox>


                    <Modal open={openModal} onClose={handleCloseModal}>
                        <MDBox
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: 400,
                                bgcolor: "background.paper",
                                border: "2px solid #000",
                                boxShadow: 24,
                                p: 4,
                            }}
                        >
                            {modalType === 'add' ? (  // Conditional rendering based on modal type
                                <AddProductForm onClose={handleCloseModal} productType={props.productType}
                                                routeType={props.routeType}/>
                            ) : (
                                <EditProductForm
                                    product={selectedProduct}  // Pass the selected product for editing
                                    onClose={handleCloseModal}
                                    productType={props.productType}
                                    routeType={props.routeType}
                                />
                            )}
                        </MDBox>
                    </Modal>
                </Card>
            </MDBox>
        </MDBox>
    );
};
