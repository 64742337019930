import {ThemeProvider} from "@mui/material/styles";
import theme from "assets/theme/index.js";
import CssBaseline from "@mui/material/CssBaseline";
import {Outlet} from "react-router-dom";

const BaseLayout = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Outlet/>
        </ThemeProvider>
    );
};
export default BaseLayout
