import MDBox from "../../components/MDBox";


export const TermsAndConditionsUser = () => {

    return (
        <MDBox>
            <h1>Terms and Conditions</h1>
        </MDBox>
    )
}