// ProfileSettings.js
import React, {useEffect} from "react";
import {Outlet, useLoaderData, useLocation, useNavigate} from "react-router-dom";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import BasicInfo from "./components/BasicInfo";
import ChangePassword from "./components/ChangePassword";
import DeleteAccount from "./components/DeleteAccount";
import {getCsrfTokenFromCookies} from "../../util/getCsrfTokenFromCookies";
import axios from "axios";

export const ProfileSettings = () => {
    const loaderData = useLoaderData();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state?.reload) {
            // Clear reload flag to prevent repeated reloads
            navigate(location.pathname, {state: {}});
            // Fetch new data by calling loader function
            profileSettingsLoader().then(updatedData => {
                // Handle updated data (e.g., update state if needed)
            }).catch(error => {
                throw new Error("Failed to fetch updated data");
            });
        }
    }, [location, navigate]);
    return (
        <MDBox mb={2}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {/* Link to the Basic Info route */}
                    <BasicInfo data={loaderData}/>
                </Grid>
                <Grid item xs={12}>
                    <ChangePassword/>
                </Grid>
                <Grid item xs={12}>

                    <DeleteAccount/>
                </Grid>
                <Outlet/>
            </Grid>
        </MDBox>
    );
};

// Loader function to provide initial data
export async function profileSettingsLoader() {
    const csrfToken = await getCsrfTokenFromCookies();
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/getuserdata`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });

    if (response.status === 200 && response.data) {
        return {
            firstName: response.data.firstname,
            lastName: response.data.lastname,
            email: response.data.email,
            phone: response.data.phone,
        };
    } else {
        throw new Error("Failed to fetch user data");
    }
}

// Actions can be handled separately in the child routes.