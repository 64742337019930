import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import 'react-circular-progressbar/dist/styles.css';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import Icon from "@mui/material/Icon";
import {RetirementContributions} from "./components/RetirementContributions";
import {TaxfreeSavings} from "./components/TaxfreeSavings";
import {MedicalAid} from "./components/MedicalAid";
import {getCsrfTokenFromCookies} from "../../util/getCsrfTokenFromCookies";
import axios from "axios";
import {useLoaderData} from "react-router-dom";
import {formatCurrency, formatPercentage} from "../../util/generalUtils";
import React from "react";
import ChatToAdvisor from "./components/ChatToAdvisor";

export const TaxSummary = () => {
    const loaderData = useLoaderData();
    const retirementProducts = loaderData.products.filter(product => product.productType === 'Retirement');
    const taxFreeProducts = loaderData.products.filter(product => product.productType === 'TaxFree');
    const medicalAidProducts = loaderData.products.filter(product => product.productType === 'MedicalAid');

    return (
        <>
            <Card sx={{width: "100%", marginTop: 1}}>
                <MDBox display="flex">
                    <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="4rem"
                        height="4rem"
                        variant="gradient"
                        bgColor="info"
                        color="white"
                        shadow="md"
                        borderRadius="xl"
                        ml={3}
                        mt={-2}
                    >
                        <Icon fontSize="medium" color="inherit">
                            assured_workload
                        </Icon>
                    </MDBox>
                    <MDTypography variant="h3" sx={{mt: 2, mb: 1, ml: 2}}>
                        Your Tax Summary
                    </MDTypography>
                </MDBox>
                <MDBox><MDTypography variant="h6" sx={{mt: 2, mb: 1, ml: 2, pt: 2, px: 2, pb: 2}}>
                    In this section, we focus on optimising your tax strategy. We assess the tax benefits you currently
                    have and explore ways to enhance your tax savings. By maximising these benefits, we can help you
                    build greater wealth while providing peace of mind for your financial future. Your designated
                    adviser will work with you to structure your tax benefits, ensuring they align with your goals and
                    are fully maximised for your advantage.
                </MDTypography></MDBox>
                <MDBox mx={4}><MDTypography variant="h4" fontWeight="medium">
                    Your tax portofolio
                    is {formatPercentage(loaderData.tax.taxTotalOptimisationPercentage)} optomised
                </MDTypography></MDBox>

                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={6}>

                        <MDBox display="flex" alignItems="center" mt={1} mb={3} mx={3}>

                            <MDBox mr={2} bgColor='info' variant='gradient' pt={2} px={2} pb={2} shadow="md"
                                   borderRadius="xl">

                                <CircularProgressbar value={loaderData.tax.taxTotalOptimisationPercentage}
                                                     text={formatPercentage(loaderData.tax.taxTotalOptimisationPercentage)}
                                                     styles={buildStyles({
                                                         trailColor: '#8f93a9',
                                                         textColor: 'white',
                                                         pathColor: '#344767',// Example of adding more styles
                                                     })}
                                />
                            </MDBox>
                            <MDBox lineHeight={1}>

                                <MDTypography variant="h6" fontWeight="medium" mt={1}>
                                    Est. Tax you pay every year: <MDTypography
                                    color='primary'>{formatCurrency(loaderData.tax.taxesPaid)}</MDTypography>
                                </MDTypography>
                                <MDTypography variant="h6" fontWeight="medium" mt={1}>
                                    Est.Tax Reduction Benefits Not Used: <MDTypography
                                    color='primary'>{formatCurrency(loaderData.tax.totalTaxReductionBenefitsNotUsed)}</MDTypography>
                                </MDTypography>
                                <MDTypography variant="h6" fontWeight="medium" mt={1}>
                                    Est. Interest Tax Benefits Not Used: <MDTypography
                                    color='primary'>{formatCurrency(loaderData.tax.totalTaxInterestBenefitsNotUsed)}</MDTypography>
                                </MDTypography>


                            </MDBox>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{textAlign: "right"}} px={3}>
                        <ChatToAdvisor source={'Tax'}/>
                        <MDBox mt={0.5}>
                            <MDTypography variant="button" color="text">
                                Get in touch with your designated advisor to save more.
                            </MDTypography>
                        </MDBox>
                    </Grid>
                </Grid>
            </Card>
            <Card sx={{width: "100%", marginTop: 2, marginBottom: 2}}>
                <RetirementContributions
                    products={retirementProducts}
                    retirementBenefitOptimisationPercentage={loaderData.tax.retirementBenefitOptimisationPercentage}
                    retirementBenefitAvailable={loaderData.tax.retirementBenefitAvailable}
                    retirementBenefitUsed={loaderData.tax.retirementBenefitUsed}
                    retirementBenefitRemaining={loaderData.tax.retirementBenefitRemaining}
                    retirementTaxCompoundedLoss={loaderData.tax.retirementTaxCompoundedLoss}
                    retirementWithTaxBenefit={loaderData.tax.retirementWithTaxBenefit}
                    retirementWithoutTaxBenefit={loaderData.tax.retirementWithoutTaxBenefit}
                    retirementTaxBenefitYears={loaderData.tax.retirementTaxBenefitYears}
                    compoundPresentValue={loaderData.tax.compoundPresentValue}
                    productType='Retirement'
                    routeType={loaderData.routeType}
                />
            </Card>
            <Card sx={{width: "100%", marginTop: 2, marginBottom: 2}}>
                <TaxfreeSavings
                    products={taxFreeProducts}
                    taxFreeSavingsPercentage={loaderData.tax.taxFreeSavingsPercentage}
                    taxFreeCompoundLoss={loaderData.tax.taxFreeCompoundLoss}
                    taxFreeWithTaxBenefit={loaderData.tax.taxFreeWithTaxBenefit}
                    taxFreeWithoutTaxBenefit={loaderData.tax.taxFreeWithoutTaxBenefit}
                    taxFreeTaxBenefitYears={loaderData.tax.taxFreeTaxBenefitYears}
                    compoundTFSPresentValue={loaderData.tax.compoundTFSPresentValue}
                    productType='TaxFree'
                    routeType={loaderData.routeType}

                />
            </Card>
            <Card sx={{width: "100%", marginTop: 2, marginBottom: 2}}>
                <MedicalAid
                    products={medicalAidProducts}
                    medicalAidPercentage={loaderData.tax.medicalAidPercentage}
                    medicalAidBenefit={loaderData.tax.medicalAidBenefit}
                    medicalAidUsed={loaderData.tax.medicalAidUsed}
                    medicalAidRemaining={loaderData.tax.medicalAidRemaining}
                    productType='MedicalAid'
                    routeType={loaderData.routeType}
                />
            </Card>
        </>
    );
}

export const taxSummaryLoader = async () => {
    const routeType = 'client';
    const csrfToken = await getCsrfTokenFromCookies();
    const userDataResponse = await axios.get(`${process.env.REACT_APP_API_URL}/auth/getuserdata`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });
    if (userDataResponse.data.basicProfileSetup === false) {
        window.location.href = '/app';
        return null;
    }
    // Fetch tax data
    const taxResponse = await axios.get(`${process.env.REACT_APP_API_URL}/clients/getTaxData`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });
    ;
    // Fetch user products
    const productsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/clients/getUserProducts`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });
    if (taxResponse.status === 200 && productsResponse.status === 200) {
        return {
            tax: taxResponse.data,  // Tax data under "tax"
            products: productsResponse.data,
            routeType: routeType,// Products data under "products"
        };
    } else {
        throw new Error("Failed to fetch user data");
    }
};

export const adminTaxSummaryLoader = async ({params}) => {
    const routeType = 'admin';
    const csrfToken = await getCsrfTokenFromCookies();
    const userDataResponse = await axios.get(`${process.env.REACT_APP_API_URL}/provider/getuserdata/${params.clientId}`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });
    if (userDataResponse.data.basicProfileSetup === false) {
        window.location.href = '/app';
        return null;
    }

    // Fetch tax data
    const taxResponse = await axios.get(`${process.env.REACT_APP_API_URL}/provider/getTaxData/${params.clientId}`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });
    // Fetch user products
    const productsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/provider/getUserProducts/${params.clientId}`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });
    if (taxResponse.status === 200 && productsResponse.status === 200) {
        return {
            tax: taxResponse.data,  // Tax data under "tax"
            products: productsResponse.data,
            routeType: routeType,
        };
    } else {
        throw new Error("Failed to fetch user data");
    }
};
