// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router components
import {Link} from "react-router-dom"; // Use react-router Link
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";

function Footer({
                    links = [
                        {href: "/app/privacy-policy", name: "Privacy Policy"}, // Update hrefs to internal routes
                        {href: "/app/terms-and-conditions", name: "Terms and Conditions"},
                    ],
                }) {
    const {size} = typography;

    const renderLinks = () =>
        links.map((link) => (
            <MDBox key={link.name} component="li" px={2} lineHeight={1}>
                <Link to={link.href}> {/* Use Link from react-router-dom */}
                    <MDTypography variant="button" fontWeight="regular" color="text">
                        {link.name}
                    </MDTypography>
                </Link>
            </MDBox>
        ));

    return (
        <MDBox
            width="100%"
            display="flex"
            flexDirection={{xs: "column", lg: "row"}}
            justifyContent="space-between"
            alignItems="center"
            px={1.5}
        >
            <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
                color="text"
                fontSize={size.sm}
                px={1.5}
            >
                &copy; {new Date().getFullYear()}, GrowYourBucks. This application's use
                is subject to our terms and conditions and privacy policy.
            </MDBox>
            <MDBox
                component="ul"
                sx={({breakpoints}) => ({
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                    listStyle: "none",
                    mt: 3,
                    mb: 0,
                    p: 0,
                    [breakpoints.up("lg")]: {
                        mt: 0,
                    },
                })}
            >
                {renderLinks()}
            </MDBox>
        </MDBox>
    );
}

// Setting default values for the props of Footer

// Typechecking props for the Footer
Footer.propTypes = {
    links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;