import React, {useState} from 'react';
import {Form, redirect, useActionData, useLocation} from "react-router-dom";
import Cookies from 'js-cookie';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "pages/public/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import {ClipLoader} from "react-spinners";
import {parsePhoneNumberFromString} from 'libphonenumber-js';

// EditPhone Component
function EditPhone() {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const phoneNumber = urlParams.get('phone'); // Get phone number from URL

    const [loading, setLoading] = useState(false);
    const [newPhone, setNewPhone] = useState(phoneNumber || ""); // Initialize with URL phone number
    const [phoneError, setPhoneError] = useState("");
    const actionData = useActionData();

    const handlePhoneBlur = (e) => {
        let value = e.target.value;

        // Convert 10-digit number starting with 0 to international format
        if (value.length === 10 && value.startsWith('0')) {
            value = `+27${value.substring(1)}`;
        }

        setNewPhone(value); // Update the state with the formatted number
        validatePhoneNumber(value); // Validate the phone number after formatting
    };

    const validatePhoneNumber = (value) => {
        if (value) {
            const phoneNumber = parsePhoneNumberFromString(value, 'ZA');
            if (!phoneNumber || (phoneNumber.nationalNumber.length !== 9 && phoneNumber.number.length !== 12)) {
                setPhoneError("Please enter a valid South African phone number.");
            } else {
                setPhoneError("");
            }
        } else {
            setPhoneError("Please enter a phone number.");
        }
    };

    const handleSubmit = async () => {
        setLoading(true); // Set loading to true on form submission
    };

    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Edit your phone number
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <Form method="post" onSubmit={handleSubmit}>
                        <MDBox mb={2}>
                            <MDInput
                                name="number"
                                type="tel"
                                label="New Phone Number"
                                value={newPhone}
                                onChange={(e) => setNewPhone(e.target.value)} // Allow the user to edit
                                onBlur={handlePhoneBlur} // Format and validate on blur
                                fullWidth
                                required
                                disabled={loading}
                            />
                            {phoneError && (
                                <MDTypography variant="body2" color="error">
                                    {phoneError}
                                </MDTypography>
                            )}
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                            <MDButton
                                type="submit"
                                variant="gradient"
                                color="info"
                                fullWidth
                                disabled={loading || !!phoneError} // Disable button if loading or there is a validation error
                            >
                                {loading ? (
                                    <>
                                        <ClipLoader size={24} color="#fff"/>
                                        {' '}
                                        Updating...
                                    </>
                                ) : (
                                    'Update Number'
                                )}
                            </MDButton>
                        </MDBox>
                        {actionData?.error && (
                            <MDBox mt={2}>
                                <MDTypography color="error" variant="body2">
                                    {actionData.error}
                                </MDTypography>
                            </MDBox>
                        )}
                    </Form>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}

export default EditPhone;

// Action function for form submission
export async function editPhoneAction({request}) {
    const formData = await request.formData();
    const newPhoneNumber = formData.get('number');

    if (!newPhoneNumber) {
        return {error: 'New phone number is missing'};
    }

    // Validate the phone number
    const phoneNumber = parsePhoneNumberFromString(newPhoneNumber, 'ZA');
    if (!phoneNumber || (phoneNumber.nationalNumber.length !== 9 && phoneNumber.number.length !== 12)) {
        return {error: 'Please enter a valid South African phone number.'};
    }

    try {
        const csrfToken = await getCsrfTokenFromCookies();

        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/updatenumber`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-csrf-token': csrfToken,
            },
            body: JSON.stringify({phone: newPhoneNumber}),
        });

        if (!response.ok) {
            const errorText = await response.text();
            return {error: errorText};
        }

        // Redirect to verify phone with the new number
        return redirect(`/verifyphone?phone=${encodeURIComponent(newPhoneNumber)}`);
    } catch (error) {
        console.error(error);
        return {error: error.message || "An error occurred."};
    }
}

// Helper function to extract CSRF token from cookies
function getCsrfTokenFromCookies() {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const csrfToken = Cookies.get('csrfToken');
            if (csrfToken) {
                resolve(csrfToken);
            } else {
                reject(new Error('CSRF token not found'));
            }
        }, 100); // Delay for 100ms before attempting to read the cookie
    });
}