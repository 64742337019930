import React, {useEffect, useState} from "react";
import {Form, Link, redirect, useActionData} from "react-router-dom";
import axios from "axios";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import BasicLayout from "./components/BasicLayout";
import bgImage from "../../assets/images/bg-sign-in-basic.jpeg";
import {parsePhoneNumberFromString} from "libphonenumber-js";


export const RequestPasswordReset = () => {
    const [resetMethod, setResetMethod] = useState(null);
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const actionData = useActionData();
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (actionData && actionData.error) {
            setIsSubmitting(false); // Reset submitting state on error
        }
    }, [actionData]);

    // Function to toggle between reset methods
    const toggleMethod = () => {
        setResetMethod((prevMethod) => (prevMethod === "email" ? "phone" : "email"));
        setPhone(""); // Clear phone number state when switching methods
        setEmail(""); // Clear email state when switching methods
        setPhoneError(""); // Clear phone error state when switching methods
        setEmailError(""); // Clear email error state when switching methods
    };

    // Handle phone number change
    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };

    // Handle email change
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    // Validate phone number on blur
    const validatePhoneNumber = () => {
        let value = phone.trim().replace(/\s+/g, ""); // Remove all spaces

        if (value) {
            // Convert local format to international format if needed
            if (value.length === 10 && value.startsWith("0")) {
                value = `+27${value.substring(1)}`; // Convert to international format
            }

            const phoneNumber = parsePhoneNumberFromString(value, "ZA");
            if (!phoneNumber || !phoneNumber.isValid()) {
                setPhoneError("Please enter a valid South African phone number.");
            } else {
                setPhoneError("");
                setPhone(phoneNumber.format("E.164")); // Format to international standard without spaces
            }
        } else {
            setPhoneError("Please enter a phone number.");
        }
    };

    // Validate email on blur
    const validateEmail = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for email format
        if (email && !emailPattern.test(email)) {
            setEmailError("Please enter a valid email address.");
        } else if (!email) {
            setEmailError("Please enter an email address.");
        } else {
            setEmailError("");
        }
    };

    // Handle form submission
    const handleSubmit = (e) => {
        if ((resetMethod === "phone" && phoneError) || (resetMethod === "email" && emailError)) {
            e.preventDefault();
            setIsSubmitting(false);
        } else {
            if (resetMethod === "phone") validatePhoneNumber();
            if (resetMethod === "email") validateEmail();
            setIsSubmitting(true);
        }
    };

    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    py={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                        Reset Password
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        You can reset your password via email or phone.
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    {!resetMethod ? (
                        <>
                            <MDBox mb={2}>
                                <MDButton
                                    variant="gradient"
                                    color="info"
                                    fullWidth
                                    onClick={() => setResetMethod("email")}
                                >
                                    Reset via Email
                                </MDButton>
                            </MDBox>
                            <MDBox mb={4}>
                                <MDButton
                                    variant="gradient"
                                    color="info"
                                    fullWidth
                                    onClick={() => setResetMethod("phone")}
                                >
                                    Reset via Phone
                                </MDButton>
                            </MDBox>
                        </>
                    ) : (
                        <Form method="post" onSubmit={handleSubmit}>
                            {actionData && actionData.error && (
                                <MDTypography variant="button" color="error" mb={2}>
                                    {actionData.error}
                                </MDTypography>
                            )}
                            {resetMethod === "email" && (
                                <MDBox mb={4}>
                                    <MDInput
                                        type="email"
                                        name="email"
                                        label="Email"
                                        variant="standard"
                                        fullWidth
                                        value={email}
                                        onChange={handleEmailChange}
                                        onBlur={validateEmail} // Validate on blur
                                        error={Boolean(emailError)} // Show error if exists
                                        helperText={emailError}
                                        required// Display error message
                                    />
                                </MDBox>
                            )}
                            {resetMethod === "phone" && (
                                <MDBox mb={4}>
                                    <MDInput
                                        type="text"
                                        name="phone"
                                        label="Phone Number"
                                        variant="standard"
                                        fullWidth
                                        value={phone}
                                        onChange={handlePhoneChange}
                                        onBlur={validatePhoneNumber} // Validate on blur
                                        error={Boolean(phoneError)} // Show error if exists
                                        helperText={phoneError}
                                        required
                                    />
                                </MDBox>
                            )}
                            <MDBox mt={6} mb={1}>
                                <MDButton
                                    variant="gradient"
                                    color="info"
                                    fullWidth
                                    type="submit"
                                    disabled={
                                        isSubmitting ||
                                        (resetMethod === "phone" && !!phoneError) ||
                                        (resetMethod === "email" && !!emailError)
                                    } // Disable button if there are any validation errors
                                >
                                    {isSubmitting ? 'Resetting...' : 'Reset'}
                                </MDButton>
                            </MDBox>
                            <MDTypography variant="button" color="text" mt={2}>
                                {resetMethod === "email" ? (
                                    <MDTypography
                                        component="span"
                                        variant="button"
                                        color="info"
                                        onClick={toggleMethod}
                                        style={{cursor: "pointer"}}
                                    >
                                        Prefer to reset via phone? Click here
                                    </MDTypography>
                                ) : (
                                    <MDTypography
                                        component="span"
                                        variant="button"
                                        color="info"
                                        onClick={toggleMethod}
                                        style={{cursor: "pointer"}}
                                    >
                                        Prefer to reset via email? Click here
                                    </MDTypography>
                                )}
                            </MDTypography>
                        </Form>
                    )}
                    <MDTypography variant="button" color="text">
                        Don&apos;t have an account?{" "}
                        <MDTypography
                            component={Link}
                            to="/signup"
                            variant="button"
                            color="info"
                            fontWeight="medium"
                            textGradient
                        >
                            Sign up
                        </MDTypography>
                    </MDTypography>
                </MDBox>
            </Card>
        </BasicLayout>
    );
};
export const requestPasswordResetAction = async ({request}) => {
    const formData = await request.formData();
    const postData = Object.fromEntries(formData);

    try {
        if (postData.email) {
            // Handle reset via email
            await axios.post(`${process.env.REACT_APP_API_URL}/auth/requestpasswordreset`, {
                email: postData.email,
            });
            return redirect('/resetrequested?type=email');
        } else if (postData.phone) {
            // Handle reset via phone
            await axios.post(`${process.env.REACT_APP_API_URL}/auth/requestpasswordreset`, {
                phone: postData.phone,
            });
            return redirect('/resetrequested?type=phone');
        }
    } catch (error) {
        console.error(error);
        // Return the error message to be displayed on the form
        return {error: error.response?.data?.message || "Failed to request password reset."};
    }
};