/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2024 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components
import {Link} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import {Breadcrumbs as MuiBreadcrumbs} from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Breadcrumbs({icon, title, route, light = false}) {
    const routes = route.slice(0, -1);

    return (
        <MDBox mr={{xs: 0, xl: 8}}>
            <MuiBreadcrumbs
                sx={{
                    "& .MuiBreadcrumbs-separator": {
                        color: ({palette: {white, grey}}) => (light ? white.main : grey[600]),
                    },
                }}
            >
                <Link to="/app">
                    <MDTypography
                        component="span"
                        variant="body2"
                        color={light ? "white" : "dark"}
                        opacity={light ? 0.8 : 0.5}
                        sx={{lineHeight: 0}}
                    >
                        <Icon>{icon}</Icon>
                    </MDTypography>
                </Link>
                {routes.length > 0 && routes.map((el, index) => (
                    el !== "app" && ( // Only render non-"app" routes
                        <Link to={`/${el}`} key={index}>
                            <MDTypography
                                component="span"
                                variant="button"
                                fontWeight="regular"
                                textTransform="capitalize"
                                color={light ? "white" : "dark"}
                                opacity={light ? 0.8 : 0.5}
                                sx={{lineHeight: 0}}
                            >
                                {el}
                            </MDTypography>
                        </Link>
                    )
                ))}
                {title !== "app" && (
                    <MDTypography
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                        color={light ? "white" : "dark"}
                        sx={{lineHeight: 0}}
                    >
                        {title.replace("-", " ")}
                    </MDTypography>
                )}
            </MuiBreadcrumbs>
        </MDBox>
    );
}

// Setting default values for the props of Breadcrumbs

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    light: PropTypes.bool,
};

export default Breadcrumbs;
